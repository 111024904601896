import React from 'react';
import './App.css';
import { useState, useEffect } from 'react';

function App() {
  const [languageIndex, setLanguageIndex] = useState(0);

  const languages = [
    { code: 'en', text: 'Our news portal is under construction. Stay tuned!' },
    { code: 'mt', text: 'हमर न्यूज पोर्टल निर्माणाधीन अछि। जुडल रहू !' },
    { code: 'ne', text: 'हाम्रो समाचार पोर्टल निर्माणाधीन छ। बन्नुहोस्!' },
    { code: 'hi', text: 'हमारी समाचार पोर्टल अभी निर्माणाधीन है। बने रहें!' },
  ];

  useEffect(() => {
    const interval = setInterval(() => {
      setLanguageIndex(index => (index + 1) % languages.length);
    }, 2000);

    return () => clearInterval(interval);
  }, []);

  const getTranslatedText = () => {
    switch (languages[languageIndex].code) {
      case 'hi':
        return languages[languageIndex].text;
      case 'ne':
        return languages[languageIndex].text;
      case 'mt':
        return languages[languageIndex].text;
      default:
        return languages[languageIndex].text;
    }
  };

  return (
    <div className="App">
      <div className="content">
        <div
        style={{marginTop:'50%'}}
        >
        <h1>{getTranslatedText()}</h1>
        </div>
        
        <div className="footer">
          <p>© 2023 <a
        target={'_blank'}
        style={{color:'white'}}
        href='https://www.sandconsole.com'>Sand Console Pvt Ltd.</a> All rights reserved. This website and its content are protected by copyright law. Any unauthorized reproduction or distribution of this website, in whole or in part, is strictly prohibited.</p>
        </div>
      </div>
    </div>
  );
}
export default App;